<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcAddCandidate }}</h1>
      <b-form validated>
        <div>
          <b-row>
            <b-col sm="6">
              <b-row>
                <b-col sm="12" class="font-style-3">
                  <label class="form-control legend-valid-field">{{
                    translations.tcFieldsWithThisStyleAreOptionalFields
                  }}</label>
                  <label class="form-control legend-invalid-field">{{
                    translations.tcFieldsWithThisStyleAreRequiredFields
                  }}</label>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcCandidateType }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcTooltipCandidateType">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
            </header>
            <div v-if="Section1">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-1"
                        :label="`${translations.tcCandidateType}:`"
                        label-for="prospect-type-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="prospect-type-select"
                          :title="`${translations.tcCandidateType}:`"
                          :options="optionsProspectTypes"
                          v-model="prospect.member_type_key"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6"></b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="prospect.member_type_key === this.Auxiliary">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcSelect }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcSelect">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section5 = !Section5" section="Section5" :status="Section5"></ToggleArrow>
            </header>
            <div v-if="Section5">
              <b-row>
                <b-col sm="12">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group label label-for class="form-element">
                        <b-form-radio-group
                          id="radio-group-2"
                          v-model="prospect.auxiliary_type"
                          name="radio-sub-component"
                        >
                          <b-form-radio value="existing">
                            <h3 class="flex-2 mr-3 text-left font-style-2">
                              {{ translations.tcSelectNonAuxiliarySpouseRecord }}
                            </h3>
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-15" class="form-element">
                        <b-form-select
                          id="existing-auxiliary"
                          :title="translations.tcExistingAuxiliary"
                          :options="optionsSpouses"
                          v-model="prospect.non_auxiliary_spouse_key"
                          class="g-select flex-0 mr-3 mb-3"
                          :disabled="prospect.auxiliary_type !== 'existing'"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group label label-for class="form-element">
                        <b-form-radio-group
                          id="radio-group-2"
                          v-model="prospect.auxiliary_type"
                          name="radio-sub-component"
                        >
                          <b-form-radio value="new">
                            <h3 class="flex-2 mr-3 text-left font-style-2">
                              {{ translations.tcCreateNewNonAuxiliarySpouse }}
                            </h3>
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-16" class="form-element">
                        <b-form-select
                          id="new-auxiliary"
                          :title="translations.tcExistingAuxiliary"
                          :options="optionsMemberProspectsNoSpouses"
                          v-model="prospect.gideon_spouse_ind_key"
                          class="g-select flex-0 mr-3 mb-3"
                          :disabled="prospect.auxiliary_type !== 'new'"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="prospect.gideon_spouse_ind_key != null">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-8"
                        :label="`${translations.tcSpouse}:`"
                        label-for="spouse-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <p class="my-2">
                          {{ prospect.spouse_name }}
                        </p>
                        <em v-if="prospect.spouse_name">{{ translations.tcSelectSavePrompt }}</em>
                        <button
                          v-else
                          @click.prevent="spouseClick()"
                          class="btn btn-primary ml-2 bottom-m-20 flex-1 mt-2"
                          :disabled="prospect.auxiliary_type !== 'new'"
                        >
                          {{ translations.tcAddSpouse }}
                        </button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="prospect.member_type_key === this.Gideon">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcName }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcTooltipNameDetails">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
            </header>
            <div v-if="Section2">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-1"
                        :label="`${translations.tcPrefix}:`"
                        label-for="prefix-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="prefix-select"
                          :title="translations.tcPrefix"
                          :options="prospect.member_type_key === this.Gideon ? optionsPrefixMale : optionsPrefixFemale"
                          v-model="prospect.prefix_key"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-1"
                        :label="`${translations.tcFirstName}:`"
                        label-for="first-name-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcFirstName"
                          id="first-name-input"
                          :placeholder="translations.tcFirstName"
                          v-model="prospect.first_name"
                          required
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-2"
                        :label="`${translations.tcMiddleName}:`"
                        label-for="middle-name-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcMiddleName"
                          id="middle-name-input"
                          :placeholder="translations.tcMiddleName"
                          v-model="prospect.middle_name"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-3"
                        :label="`${translations.tcLastName}:`"
                        label-for="last-name-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcLastName"
                          id="last-name-input"
                          :placeholder="translations.tcLastName"
                          v-model="prospect.last_name"
                          trim
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="prospect.gender === 'Male'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-2"
                        :label="`${translations.tcSuffix}:`"
                        label-for="suffix-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="suffix-select"
                          :title="translations.tcSuffix"
                          :options="optionsSuffix"
                          v-model="prospect.suffix_key"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-5"
                        :label="`${translations.tcInformalName}:`"
                        label-for="informal-name-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcInformalName"
                          id="informal-name-input"
                          :placeholder="translations.tcInformalName"
                          v-model="prospect.informal_name"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-3"
                        :label="`${translations.tcPreferredName}:`"
                        label-for="preferred-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="preferred-select"
                          :title="translations.tcPreferredName"
                          :options="optionsPreferredName"
                          v-model="prospect.preferred_name"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="prospect.member_type_key === this.Gideon">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcMailingAddress }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcMailingAddress">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
            </header>
            <div v-if="Section3">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-addr1"
                        :label="`${translations.tcAddress1}:`"
                        label-for="address1-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcAddress1"
                          id="address1-input"
                          :placeholder="translations.tcAddress1"
                          v-model="prospect.address1"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-addr2"
                        :label="`${translations.tcAddress2}:`"
                        label-for="address2-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcAddress2"
                          id="address2-input"
                          :placeholder="translations.tcAddress2"
                          v-model="prospect.address2"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-city"
                        :label="`${translations.tcCity}:`"
                        label-for="city-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcCity"
                          id="city-input"
                          :placeholder="translations.tcCity"
                          v-model="prospect.city"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="prospect.cnt_key === usCountry">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-state"
                        :label="`${translations.tcState}:`"
                        label-for="state-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="state-select"
                          :title="translations.tcState"
                          :options="optionsStates"
                          v-model="prospect.state"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="prospect.cnt_key !== usCountry">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-state-province"
                        :label="`${translations.tcStateOrProvince}:`"
                        label-for="state-province-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcStateOrProvince"
                          id="state-province-input"
                          :placeholder="translations.tcStateOrProvince"
                          v-model="prospect.state"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-postal-code"
                        :label="`${translations.tcPostalCode}:`"
                        label-for="postal-code-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          title="postal-code"
                          id="postal-code-input"
                          :placeholder="translations.tcPostalCode"
                          v-model="prospect.postal_code"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-country"
                        :label="`${translations.tcCountry}:`"
                        label-for="country-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="country-select"
                          :title="translations.tcCountry"
                          :options="optionsCountries"
                          v-model="prospect.cnt_key"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-3"
                        :label="`${translations.tcPhone}:`"
                        label-for="phone-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcPhone"
                          id="phone-input"
                          :placeholder="translations.tcPhone"
                          v-model="prospect.phone"
                          trim
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-3"
                        :label="`${translations.tcEmail}:`"
                        label-for="email-input"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-input
                          :title="translations.tcEmail"
                          id="email-input"
                          :placeholder="translations.tcEmail"
                          v-model="prospect.email"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="prospect.member_type_key === this.Gideon">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcSpouseInformation }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcSpouseInformation">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
            </header>
            <div v-if="Section3">
              <b-row>
                <b-col sm="12">
                  <b-row>
                    <b-col sm="6" class="font-style-3">
                      <template>
                        <b-form-group
                          id="select-group-30"
                          :label="`${translations.tcPrefix}:`"
                          label-for="spouse-prefix-select"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-select
                            id="spouse-prefix-select"
                            :title="translations.tcPrefix"
                            :options="optionsPrefixFemale"
                            v-model="prospect.spouse_prefix_key"
                            class="g-select flex-0"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          id="input-group-31"
                          :label="`${translations.tcFirstName}:`"
                          label-for="spouse-first-name-input"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-input
                            :title="translations.tcFirstName"
                            id="spouse-first-name-input"
                            :placeholder="translations.tcFirstName"
                            v-model="prospect.spouse_first_name"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="input-group-32"
                          :label="`${translations.tcLastName}:`"
                          label-for="spouse-last-name-input"
                          label-class="form-group-label"
                          class="form-element mb-5"
                        >
                          <b-form-input
                            :title="translations.tcLastName"
                            id="spouse-last-name-input"
                            :placeholder="translations.tcLastName"
                            v-model="prospect.spouse_last_name"
                            trim
                          ></b-form-input>
                        </b-form-group>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="prospect.member_type_key === this.Gideon">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcProfileInformation }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcProfileInformation">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section4 = !Section4" section="Section2" :status="Section4"></ToggleArrow>
            </header>
            <div v-if="Section4">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="6" class="font-style-3">
                      <b-form-group
                        id="select-group-8"
                        :label="`${translations.tcRecommendedBy}:`"
                        label-for="recommendedby-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="recommendedby-select"
                          :title="translations.tcRecommendedBy"
                          v-model="prospect.recommended_by"
                          :options="optionsRecommendedBy"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group
                        id="select-recommended-member"
                        class="form-element"
                        v-if="prospect.recommended_by === 0"
                      >
                        <b-form-select
                          id="recommended-member-select"
                          :title="translations.tcMember"
                          v-model="prospect.recommended_ind_key"
                          :options="[...searchedMember]"
                          class="col-left-input g-select flex-0 mr-3 mb-3"
                          :required="this.memberRequired"
                        ></b-form-select>
                        <b-button
                          :name="`findRecommendedByMemberButton`"
                          variant="primary"
                          class="flex-1 btn-pl-xsm"
                          @click="showModal($event, 'personSearchModal')"
                          >{{ translations.tcFind }}</b-button
                        >
                      </b-form-group>
                      <template v-if="prospect.recommended_by === 1">
                        <template v-if="!createPastor">
                          <b-form-group id="select-recommended-pastor" class="form-element">
                            <b-form-select
                              id="recommended-pastor-select"
                              :title="translations.tcPastor"
                              v-model="prospect.recommended_ind_key"
                              :options="[...searchedPastor]"
                              class="form-control col-left-input g-select flex-0 mr-3"
                              :required="this.pastorRequired"
                            ></b-form-select>
                            <b-button
                              :name="`findRecommendedByPastorButton`"
                              variant="primary"
                              class="flex-1 btn-pl-xsm"
                              @click="createNewPastor()"
                              >{{ translations.tcCreatePastor }}</b-button
                            >
                          </b-form-group>
                        </template>
                        <template v-if="createPastor">
                          <b-form-group
                            id="select-group-20"
                            :label="`${translations.tcPrefix}:`"
                            label-for="pastor-prefix-select"
                            label-class="form-group-label"
                            class="form-element"
                          >
                            <b-form-select
                              id="pastor-prefix-select"
                              :title="translations.tcPrefix"
                              :options="optionsPrefixPastor"
                              v-model="prospect.pastor_prefix_key"
                              class="g-select flex-0"
                            ></b-form-select>
                          </b-form-group>
                          <b-form-group
                            id="input-group-21"
                            :label="`${translations.tcFirstName}:`"
                            label-for="pastor-first-name-input"
                            label-class="form-group-label"
                            class="form-element"
                          >
                            <b-form-input
                              :title="translations.tcFirstName"
                              id="pastor-first-name-input"
                              :placeholder="translations.tcFirstName"
                              v-model="prospect.pastor_first_name"
                              required
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            id="input-group-22"
                            :label="`${translations.tcLastName}:`"
                            label-for="pastor-last-name-input"
                            label-class="form-group-label"
                            class="form-element mb-5"
                          >
                            <b-form-input
                              :title="translations.tcLastName"
                              id="pastor-last-name-input"
                              :placeholder="translations.tcLastName"
                              v-model="prospect.pastor_last_name"
                              trim
                            ></b-form-input>
                          </b-form-group>
                        </template>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <template v-if="!createChurch">
                        <b-form-group
                          id="select-group-4"
                          :label="`${translations.tcChurchMembership}:`"
                          label-for="church-select"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-select
                            id="church-select"
                            :title="translations.tcChurchMembership"
                            v-model="prospect.church_key"
                            :options="searchedChurch"
                            class="g-select flex-0 mr-3 mb-3"
                          ></b-form-select>
                          <b-button
                            :name="`findChurchButton`"
                            variant="primary"
                            class="flex-1 btn-pl-xsm mr-3"
                            @click="showModal($event, 'churchSearchModal')"
                            >{{ translations.tcFind }}</b-button
                          >
                          <b-button
                            :name="`createChurchButton`"
                            variant="primary"
                            class="flex-1 btn-pl-xsm"
                            @click="createNewChurch()"
                            >{{ translations.tcCreateChurch }}</b-button
                          >
                        </b-form-group>
                      </template>
                      <template v-if="createChurch">
                        <b-form-group
                          id="input-group-30"
                          :label="`${translations.tcChurchName}:`"
                          label-for="church-name-input"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-input
                            :title="translations.tcChurchName"
                            id="church-name-input"
                            :placeholder="translations.tcChurchName"
                            v-model="prospect.church_name"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="input-group-addrch"
                          :label="`${translations.tcAddress1}:`"
                          label-for="church-address1-input"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-input
                            :title="translations.tcAddress1"
                            id="church-address1-input"
                            :placeholder="translations.tcAddress1"
                            v-model="prospect.church_address1"
                            trim
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="input-group-citych"
                          :label="`${translations.tcCity}:`"
                          label-for="church-city-input"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-input
                            :title="translations.tcCity"
                            id="church-city-input"
                            :placeholder="translations.tcCity"
                            v-model="prospect.church_city"
                            trim
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="select-group-statech"
                          :label="`${translations.tcState}:`"
                          label-for="church-state-select"
                          label-class="form-group-label"
                          class="form-element"
                          v-if="prospect.church_cnt_key === usCountry && createChurch"
                        >
                          <b-form-select
                            id="church-state-select"
                            :title="translations.tcState"
                            :options="optionsStates"
                            v-model="prospect.church_state"
                            class="g-select flex-0 mr-3 mb-3"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          id="select-group-state-provincech"
                          :label="`${translations.tcStateOrProvince}:`"
                          label-for="church-state-province-select"
                          label-class="form-group-label"
                          class="form-element"
                          v-if="prospect.church_cnt_key !== usCountry && createChurch"
                        >
                          <b-form-input
                            :title="translations.tcStateOrProvince"
                            id="church-state-province-input"
                            :placeholder="translations.tcStateOrProvince"
                            v-model="prospect.church_state"
                            trim
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="input-group-postal-codech"
                          :label="`${translations.tcPostalCode}:`"
                          label-for="church-postal-code-input"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-input
                            title="postal-code"
                            id="church-postal-code-input"
                            :placeholder="translations.tcPostalCode"
                            v-model="prospect.church_postal_code"
                            trim
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="select-group-countrych"
                          :label="`${translations.tcCountry}:`"
                          label-for="church-country-select"
                          label-class="form-group-label"
                          class="form-element"
                        >
                          <b-form-select
                            id="church-country-select"
                            :title="translations.tcCountry"
                            :options="optionsCountries"
                            v-model="prospect.church_cnt_key"
                            class="g-select flex-0 mr-3 mb-5"
                          ></b-form-select>
                        </b-form-group>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-5"
                        :label="`${translations.tcDenomination}:`"
                        label-for="denomination-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="denomination-select"
                          :title="translations.tcDenomination"
                          :options="optionsDenomination"
                          v-model="prospect.denomination_key"
                          class="g-select flex-0 mr-3 mb-3"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3 mb-3">
                      <b-form-group
                        id="select-group-6"
                        :label="`${translations.tcOccupation}:`"
                        label-for="occupation-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="occupation-select"
                          :title="translations.tcOccupation"
                          :options="optionsOccupationTranslated"
                          v-model="prospect.occupation_key"
                          class="g-select flex-0 mr-3"
                          required
                        ></b-form-select>
                      </b-form-group>
                      <span class="mb-3">{{ translations.tcOtherOccupationSelected }}</span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="text-group-1"
                        :label="`${translations.tcOtherInformation}:`"
                        label-for="comment-textarea"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-textarea
                          id="comment-textarea"
                          v-model="prospect.comment"
                          :placeholder="translations.tcOtherInformation"
                          rows="4"
                          max-rows="6"
                          trim="trim"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary" @click="handleSaveClick()" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
                translations.tcSave
              }}</b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
                translations.tcCancel
              }}</b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :members_only_flag="0"
      :not_a_member="0"
      :camp_country="campCountryKey"
    ></PersonSearch>
    <ChurchSearch
      @selectionMade="applyFoundChurch($event)"
      :camp_country="campCountryKey"
      :i18n="translations.components"
    >
    </ChurchSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import ChurchSearch from '@/components/ChurchSearch.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-prospect',
  mixins: [translationMixin],
  data() {
    return {
      Gideon: 'abda920a-f939-4de0-bad5-0ab91f476b5e',
      Auxiliary: '678cfa9f-3ced-4880-ab74-6bad634aaf74',
      translations: {},
      Section1: true,
      Section2: true,
      Section3: true,
      Section4: true,
      Section5: true,
      trim: true,
      createPastor: false,
      createChurch: false,
      findButton: '',
      optionsOccupationTranslated: [],
      searchedSpouse: [],
      searchedGideonSpouse: [],
      searchedMember: [],
      searchedPastor: [],
      searchedChurch: [],
      presetDate: null,
      errMessage: [],
      phone_type: 'constant_pht_key_home', //'7624F304-AE77-4185-89DC-8ADF63E7E1F7',
      address_type: 'constant_adt_key_mailing', //'D6A4A54B-B7C9-4629-B7C8-0AE267DA612A',
      email_type: 'constant_emt_key_home', //'D5BE37A2-D7A4-4FF5-87E0-EDBE263F337F',
      campCountryKey: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      usCountry: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',

      prospect: {
        auxiliary_type: 'existing',
        member_type_key: '',
        user_ind_key: this.userIndKey,
        user_camp_key: this.userCampKey,
        non_auxiliary_spouse_key: null,
        gideon_spouse_ind_key: null,
        ind_key: null,
        prefix_key: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix_key: null,
        informal_name: '',
        preferred_name: 'F',
        address1: '',
        address2: '',
        city: '',
        state: null,
        postal_code: '',
        cnt_key: null, //'bc4b70f8-280e-4bb0-b935-9f728c50e183',
        phone: '',
        email: '',
        spouse_ind_key: null,
        spouse_name: '',
        spouse_prefix_key: null,
        spouse_first_name: '',
        spouse_last_name: '',
        denomination_key: null,
        occupation_key: null,
        comment: '',
        recommended_by: 0,
        recommended_ind_key: null,
        recommended_ind_name: '',
        recommended_ind_type: null,
        pastor_prefix_key: null,
        pastor_first_name: '',
        pastor_last_name: '',
        church_key: null,
        church_name: '',
        church_address1: '',
        church_city: '',
        church_state: '',
        church_postal_code: '',
        church_cnt_key: null,
      },
      optionsPreferredName: [
        { text: 'Formal', value: 'F' },
        { text: 'Informal', value: 'I' },
      ],
    }
  },
  methods: {
    ...mapActions({
      clearIndividualProfile: 'membership/clearIndividualProfile',
      clearProspectProfile: 'membership/clearProspectProfile',
      loadProspectFormOptions: 'membership/loadProspectFormOptions',
      saveAddProspect: 'membership/saveAddProspect',
      setLoadingStatus: 'menu/setLoadingStatus',
      setProspectProfile: 'membership/setProspectProfile',
      setRouterBackLink: 'user/setRouterBackLink',
    }),
    async pageLoad() {
      await Promise.all([this.setLoadingStatus(true), await this.loadProspectFormOptions(this.userCampKey)]).then(
        () => {
          this.setLoadingStatus(false)
          this.prospect.cnt_key = !!this.convertedProspectFormOptions.countriesByCamp[0]
            ? this.convertedProspectFormOptions.countriesByCamp[0].value
            : null
          this.prospect.church_cnt_key = !!this.convertedProspectFormOptions.countriesByCamp[0]
            ? this.convertedProspectFormOptions.countriesByCamp[0].value
            : null
          this.campCountryKey = !!this.convertedProspectFormOptions.countriesByCamp[0]
            ? this.convertedProspectFormOptions.countriesByCamp[0].value
            : this.usCountry
          this.prospect.member_type_key = this.membertypedefault
          Object.assign(this.prospect, this.prospectProfile)
          if (this.prospect.spouse_ind_key !== null) {
            this.searchedSpouse.push({
              value: this.prospect.spouse_ind_key,
              text: this.prospect.spouse_name,
            })
          }
          if (this.prospect.non_auxiliary_spouse_key !== null) {
            this.searchedGideonSpouse.push({
              value: this.prospect.non_auxiliary_spouse_key,
              text: this.prospect.spouse_name,
            })
          }
          this.searchedPastor = [
            { value: null, text: this.translations.tcSelectPastor, disabled: true },
            ...this.optionsPastors,
          ]
          this.searchedMember = [
            { value: null, text: this.translations.tcSelectMember, disabled: true },
            ...this.optionsMembers,
          ]
          this.searchedChurch = [...this.optionsChurch]
          this.clearIndividualProfile()
        }
      )
    },
    async handleCancelClick() {
      this.$router.push({ name: 'prospects' })
    },
    async handleSaveClick() {
      if (
        this.prospect.member_type_key === this.Gideon &&
        (this.prospect.first_name === '' ||
          this.prospect.last_name === '' ||
          this.prospect.phone === '' ||
          this.prospect.occupation_key === null)
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcFirstLastPhoneOccupationRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (this.prospect.member_type_key === this.Auxiliary && this.prospect.auxiliary_type === null) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcMakeSelectionBeforeSaving,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (
        this.prospect.member_type_key === this.Gideon &&
        this.prospect.recommended_by === 0 &&
        this.prospect.recommended_ind_key === null
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcSelectMemberRecommending,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (
        this.prospect.member_type_key === this.Gideon &&
        this.prospect.recommended_by === 1 &&
        this.prospect.recommended_ind_key === null &&
        !this.createPastor
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcSelectPastorRecommending,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (
        this.prospect.member_type_key === this.Gideon &&
        this.prospect.recommended_by === 1 &&
        this.createPastor &&
        (this.prospect.pastor_first_name === '' || this.prospect.pastor_last_name === '')
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcSelectPastorRecommending,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (
        this.prospect.member_type_key === this.Auxiliary &&
        this.prospect.auxiliary_type === 'existing' &&
        this.prospect.non_auxiliary_spouse_key === null
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcNonAuxiliaryRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (
        this.prospect.member_type_key === this.Auxiliary &&
        this.prospect.auxiliary_type === 'new' &&
        (this.prospect.gideon_spouse_ind_key === null || this.prospect.non_auxiliary_spouse_key === null)
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcGideonAndNonAuxiliaryRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        var payload = {
          auxiliary_type: this.prospect.auxiliary_type,
          member_type_key: this.prospect.member_type_key,
          user_ind_key: this.userIndKey,
          user_camp_key: this.userCampKey,
          non_auxiliary_spouse_key: this.prospect.non_auxiliary_spouse_key,
          gideon_spouse_ind_key: this.prospect.gideon_spouse_ind_key,
          prefix_key: this.prospect.prefix_key,
          first_name: this.prospect.first_name,
          middle_name: this.prospect.middle_name,
          last_name: this.prospect.last_name,
          suffix_key: this.prospect.suffix_key,
          informal_name: this.prospect.informal_name,
          preferred_name: this.prospect.preferred_name,
          address1: this.prospect.address1,
          address2: this.prospect.address2,
          city: this.prospect.city,
          state: this.prospect.state,
          postal_code: this.prospect.postal_code,
          cnt_key: this.prospect.cnt_key,
          phone: this.prospect.phone,
          email: this.prospect.email,
          church_key: this.prospect.church_key,
          church_name: this.prospect.church_name,
          church_address1: this.prospect.church_address1,
          church_city: this.prospect.church_city,
          church_state: this.prospect.church_state,
          church_postal_code: this.prospect.church_postal_code,
          church_cnt_key: this.prospect.church_cnt_key,
          denomination_key: this.prospect.denomination_key,
          occupation_key: this.prospect.occupation_key,
          comment: this.prospect.comment,
          spouse_prefix_key: this.prospect.spouse_prefix_key,
          spouse_first_name: this.prospect.spouse_first_name,
          spouse_last_name: this.prospect.spouse_last_name,
          recommended_ind_name: this.prospect.recommended_ind_key
            ? this.getRecommendedName(this.prospect.recommended_ind_key)
            : null,
          recommended_ind_key: this.prospect.recommended_ind_key,
          recommended_ind_type: this.getRecommendedIndType(),
          pastor_prefix_key: this.prospect.pastor_prefix_key,
          pastor_first_name: this.prospect.pastor_first_name,
          pastor_last_name: this.prospect.pastor_last_name,
        }
        let result = true
        await Promise.all([this.setLoadingStatus(true), (result = await this.saveAddProspect(payload))]).then(() => {
          if (result.data.response === '0') {
            console.error('errormessage: ', this.errMessage)
            this.errMessage = result.data.message
            console.error('errormessage: ', this.errMessage)
            result = false
          }
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcCandidateWasSaved : this.translations.tcErrorCandidateNotSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.setLoadingStatus(true)
              this.clearProspectProfile()
              this.setLoadingStatus(false)
              this.$router.push({ name: 'prospects' })
            }
          })
        })
      }
    },
    async spouseClick() {
      this.setProspectProfile(this.prospect)
      this.setRouterBackLink(this.$route.name)
      this.$router.push('/programs/mb/add-spouse')
    },
    createNewPastor() {
      this.prospect.recommended_ind_key = null
      this.createPastor = true
    },
    createNewChurch() {
      this.prospect.church_key = null
      this.createChurch = true
    },
    getRecommendedName(ind_key) {
      if (this.prospect.recommended_by === 0) {
        const ref = this.searchedMember.filter((member) => member.value === ind_key)
        let refName = ref[0].text
        return refName
      } else {
        const ref = this.searchedPastor.filter((pastor) => pastor.value === ind_key)
        let refName = ref[0].text
        return refName
      }
    },
    getRecommendedIndType() {
      if (this.prospect.recommended_by === 0) {
        const ref = this.convertedProspectFormOptions.referralTypes.filter(
          (x) => x.text === 'constant_irt_key_camp_member'
        )
        let refType = ref[0].value
        this.prospect.pastor_prefix_key = null
        this.prospect.pastor_first_name = ''
        this.prospect.pastor_last_name = ''
        return refType
      } else {
        const ref = this.convertedProspectFormOptions.referralTypes.filter((x) => x.text === 'constant_irt_key_pastor')
        let refType = ref[0].value
        return refType
      }
    },
    getSpouseName(ind_key) {
      const ref = this.searchedSpouse.filter((spouse) => spouse.value === ind_key)
      let refName = ref[0].text
      return refName
    },
    async applyFoundPerson(evt) {
      switch (this.findButton) {
        case 'findSpouseButton':
          this.searchedSpouse.push({ value: evt.ind_key, text: evt.name_text })
          this.prospect.spouse_ind_key = evt.ind_key
          break
        case 'findGideonSpouseButton':
          this.searchedGideonSpouse.push({
            value: evt.ind_key,
            text: evt.name_text,
          })
          this.prospect.non_auxiliary_spouse_key = evt.ind_key
          break
        case 'findRecommendedByMemberButton':
          this.searchedMember.push({ value: evt.ind_key, text: evt.name_text })
          this.prospect.recommended_ind_key = evt.ind_key
          break
        case 'findRecommendedByPastorButton':
          this.searchedPastor.push({ value: evt.ind_key, text: evt.name_text })
          this.prospect.recommended_ind_key = evt.ind_key
          break
        default:
          console.error('Default person, why are we here?')
          break
      }
    },
    async applyFoundChurch(evt) {
      this.searchedChurch.push({ value: evt.org_key, text: evt.name_text })
      this.prospect.church_key = evt.org_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('church-search'),
      this.getComponentTranslations('common.occupation-type'),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
      this.translateDropdownOptions([{ node: 'occupation-type', store: 'optionsOccupation' }])
      this.optionsPreferredName[0].text = this.translations.tcFormal
      this.optionsPreferredName[1].text = this.translations.tcInformal
    })
  },
  computed: {
    ...mapGetters({
      convertedProspectFormOptions: 'membership/convertedProspectFormOptions',
      membertypedefault: 'membership/memberTypeDefault',
      prefCulture: 'user/userPreferredCulture',
      prospectProfile: 'membership/prospectProfile',
      user: 'user/demograph',
      userCampKey: 'user/userCampKey',
      userIndKey: 'user/userId',
      userName: 'user/userName',
    }),

    // FormOptions
    optionsPrefixMale() {
      return [
        { value: null, text: this.translations.tcSelectPrefix, disabled: true },
        ...this.convertedProspectFormOptions.prefixMale,
      ]
    },
    optionsPrefixFemale() {
      return [
        { value: null, text: this.translations.tcSelectPrefix, disabled: true },
        ...this.convertedProspectFormOptions.prefixFemale,
      ]
    },
    optionsPrefixPastor() {
      return [
        { value: null, text: this.translations.tcSelectPrefix, disabled: true },
        ...this.convertedProspectFormOptions.prefixMale,
        ...this.convertedProspectFormOptions.prefixFemale,
      ]
    },
    optionsSuffix() {
      return [
        { value: null, text: this.translations.tcSelectSuffix, disabled: true },
        ...this.convertedProspectFormOptions.suffixMale,
      ]
    },
    optionsChurch() {
      return [
        { value: null, text: this.translations.tcSelectChurch, disabled: true },
        ...this.convertedProspectFormOptions.churches,
      ]
    },
    optionsDenomination() {
      return [
        { value: null, text: this.translations.tcSelectDenomination, disabled: true },
        ...this.convertedProspectFormOptions.denominations,
      ]
    },
    optionsOccupation() {
      return [
        { value: null, text: this.translations.tcSelectOccupation, disabled: true },
        ...this.convertedProspectFormOptions.occupations,
      ]
    },
    optionsLanguage() {
      return [
        { value: null, text: this.translations.tcSelectLanguage, disabled: true },
        ...this.convertedProspectFormOptions.languages,
      ]
    },
    optionsRecommendedBy() {
      return [
        { value: 0, text: this.translations.tcMember },
        { value: 1, text: this.translations.tcPastor },
      ]
    },
    optionsRecommendedByMembers() {
      return [
        { value: null, text: this.translations.tcSelectMember, disabled: true },
        ...this.convertedProspectFormOptions.membersByCamp,
      ]
    },
    optionsProspectTypes() {
      let convertedTypes = this.convertedProspectFormOptions.memberTypes.map((m) => {
        const memberType = `tc${m.text}`
        const memberTypeText = this.translations.hasOwnProperty(memberType) ? this.translations[memberType] : m.text
        return { ...m, text: memberTypeText }
      })
      return convertedTypes
    },
    optionsStates() {
      return [...this.convertedProspectFormOptions.states]
    },
    optionsCountries() {
      return [...this.convertedProspectFormOptions.countries]
    },
    optionsRecommendedByPastor() {
      return [{ value: null, text: this.translations.tcSelectPastor, disabled: true }]
    },
    optionsMembers() {
      return [...this.convertedProspectFormOptions.membersByCamp]
    },
    optionsPastors() {
      return [...this.convertedProspectFormOptions.pastorsByCamp]
    },
    optionsSpouses() {
      return [{ text: `${this.translations.tcSelect}...`, value: null }, ...this.convertedProspectFormOptions.spouses]
    },
    optionsMemberProspectsNoSpouses() {
      return [
        { text: `${this.translations.tcSelect}...`, value: null },
        ...this.convertedProspectFormOptions.membersprospects,
      ]
    },
    showSpouse() {
      return true
    },
    memberRequired() {
      return (
        this.prospect.member_type_key === this.Gideon &&
        this.prospect.recommended_by === 0 &&
        this.prospect.recommended_ind_key === null
      )
    },
    pastorRequired() {
      return (
        this.prospect.member_type_key === this.Gideon &&
        this.prospect.recommended_by === 1 &&
        this.prospect.recommended_ind_key === null &&
        !this.createPastor
      )
    },
  },
  components: {
    ChurchSearch: ChurchSearch,
    iTooltip: iTooltip,
    pageBody: pageBody,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;
  @include breakpoint(sm) {
    width: 24px;
  }
  input {
    order: 2;
  }
  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;
    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;
    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .g-col-2 {
    max-width: 247px;
    width: 100%;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

.form-group-label {
  margin-bottom: 0;
  color: #636363;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
}
// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

label.legend-valid-field {
  border-color: #28a745;
  color: #28a745;
  padding: 6px 20px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: right calc(0.375em + 0.1875rem) center;
}

label.legend-invalid-field {
  border-color: #dc3545;
  color: #dc3545;
  padding: 6px 20px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: right calc(0.375em + 0.1875rem) center;
}
</style>
